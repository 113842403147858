<template>
  <section class="cases" id="cases" data-view>
    <Header></Header>
    <scrollAnalytics></scrollAnalytics>

    <div class="cases__content">
      <div class="cases__top">
        <h2 class="section__title cases__title iosTitle type2">
          <span class="text-wrapper">
            <span class="letters">
              {{ `${$t("cases.title[0]") + "&nbsp;"}` }}
            </span>
          </span>
          <span class="text-wrapper">
            <span class="letters">
              {{ $t("cases.title[1]") }}
            </span>
          </span>
        </h2>
        <!--        <div class="cases__description">{{ $t('cases.description') }}</div>-->
      </div>
      <div class="cases__list">
        <div class="cases__item appear cursor-hover" @click="toLink('/cases/vitrolla')">
          <div class="cases__item--title">
            <a class="underline">
              {{ $t("cases.list.vitrolla.name") }}
            </a>
          </div>
          <div class="cases__item--details">
            <img class="cases__img" src="@/assets/img/cases/main/Meet-Vitrolya.png" alt=""/>
            <p class="cases__item--text">{{ $t("cases.list.vitrolla.details") }}</p>
            <svg class="cases__item--icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4531 11.5684L30.5677 11.5686L30.5677 26.6965" stroke="black" stroke-linecap="round"/>
              <path d="M30.2231 11.8588L14.0016 28.0807" stroke="black" stroke-linecap="round"/>
            </svg>
          </div>
        </div>

        <div class="cases__item appear cursor-hover" @click="toLink('/cases/butt')">
          <div class="cases__item--title">
            <a class="underline">
              {{ $t("cases.list.butt.name") }}
            </a>
          </div>
          <div class="cases__item--details">
            <img class="cases__img" src="@/assets/img/cases/main/The-happy-butt.png" alt=""/>
            <p class="cases__item--text">{{ $t("cases.list.butt.details") }}</p>
            <svg class="cases__item--icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4531 11.5684L30.5677 11.5686L30.5677 26.6965" stroke="black" stroke-linecap="round"/>
              <path d="M30.2231 11.8588L14.0016 28.0807" stroke="black" stroke-linecap="round"/>
            </svg>
          </div>
        </div>

        <div class="cases__item appear cursor-hover" @click="toLink('/cases/shift-work')">
          <div class="cases__item--title">
            <a class="underline">
              {{ $t("cases.list.shiftWork.name") }}
            </a>
          </div>
          <div class="cases__item--details">
            <img class="cases__img" src="@/assets/img/cases/main/Shift-work-disorder.png" alt=""/>
            <p class="cases__item--text">{{ $t("cases.list.shiftWork.details") }}</p>
            <svg class="cases__item--icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4531 11.5684L30.5677 11.5686L30.5677 26.6965" stroke="black" stroke-linecap="round"/>
              <path d="M30.2231 11.8588L14.0016 28.0807" stroke="black" stroke-linecap="round"/>
            </svg>
          </div>
        </div>

        <div class="cases__item appear cursor-hover" @click="toLink('/cases/vacciland')">
          <div class="cases__item--title">
            <a class="underline">
              {{ $t("cases.list.vaccinland.name") }}
            </a>
          </div>
          <div class="cases__item--details">
            <img class="cases__img" src="@/assets/img/cases/main/Vacciland.png" alt=""/>
            <p class="cases__item--text">{{ $t("cases.list.vaccinland.details") }}</p>
            <svg class="cases__item--icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4531 11.5684L30.5677 11.5686L30.5677 26.6965" stroke="black" stroke-linecap="round"/>
              <path d="M30.2231 11.8588L14.0016 28.0807" stroke="black" stroke-linecap="round"/>
            </svg>
          </div>
        </div>

        <div class="cases__item appear cursor-hover" @click="toLink('/cases/happy-liver')">
          <div class="cases__item--title">
            <a class="underline" v-html="$t('cases.list.happyLiver.name')"> </a>
          </div>
          <div class="cases__item--details">
            <img class="cases__img" src="@/assets/img/cases/main/happy-liver.png" alt=""/>
            <p class="cases__item--text">{{ $t("cases.list.happyLiver.details") }}</p>
            <svg class="cases__item--icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4531 11.5684L30.5677 11.5686L30.5677 26.6965" stroke="black" stroke-linecap="round"/>
              <path d="M30.2231 11.8588L14.0016 28.0807" stroke="black" stroke-linecap="round"/>
            </svg>
          </div>
        </div>

        <div class="cases__item appear cursor-hover" @click="toLink('/cases/periods')">
          <div class="cases__item--title">
            <a class="underline" v-html="$t('cases.list.noSpa.name')"> </a>
          </div>
          <div class="cases__item--details">
            <img class="cases__img" src="@/assets/img/cases/main/No-Spa.png" alt=""/>
            <p class="cases__item--text">{{ $t("cases.list.noSpa.details") }}</p>
            <svg class="cases__item--icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4531 11.5684L30.5677 11.5686L30.5677 26.6965" stroke="black" stroke-linecap="round"/>
              <path d="M30.2231 11.8588L14.0016 28.0807" stroke="black" stroke-linecap="round"/>
            </svg>
          </div>
        </div>

        <div @click="toLink('/cases/allegra')" class="cases__item appear cursor-hover">
          <div class="cases__item--title">
            <a class="underline" v-html="$t('cases.list.allegra.name')"> </a>
          </div>
          <div class="cases__item--details">
            <img class="cases__img" src="@/assets/img/cases/main/Allegra.png" alt=""/>
            <p class="cases__item--text">{{ $t("cases.list.allegra.details") }}</p>
            <svg class="cases__item--icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4531 11.5684L30.5677 11.5686L30.5677 26.6965" stroke="black" stroke-linecap="round"/>
              <path d="M30.2231 11.8588L14.0016 28.0807" stroke="black" stroke-linecap="round"/>
            </svg>
          </div>
        </div>

        <div class="cases__item appear cursor-hover" @click="toLink('/cases/listerine')">
          <div class="cases__item--title">
            <a class="underline" v-html="$t('cases.list.listerin.name')"> </a>
          </div>
          <div class="cases__item--details">
            <img class="cases__img" src="@/assets/img/cases/main/Listerine.png" alt=""/>
            <p class="cases__item--text">{{ $t("cases.list.listerin.details") }}</p>
            <svg class="cases__item--icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4531 11.5684L30.5677 11.5686L30.5677 26.6965" stroke="black" stroke-linecap="round"/>
              <path d="M30.2231 11.8588L14.0016 28.0807" stroke="black" stroke-linecap="round"/>
            </svg>
          </div>
        </div>

        <div class="cases__item appear cursor-hover" @click="toLink('/cases/hepato')">
          <div class="cases__item--title">
            <a class="underline" v-html="$t('cases.list.hepato.name')"> </a>
          </div>
          <div class="cases__item--details">
            <img class="cases__img" src="@/assets/img/cases/main/Essentiale.png" alt=""/>
            <p class="cases__item--text">{{ $t("cases.list.hepato.details") }}</p>
            <svg class="cases__item--icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4531 11.5684L30.5677 11.5686L30.5677 26.6965" stroke="black" stroke-linecap="round"/>
              <path d="M30.2231 11.8588L14.0016 28.0807" stroke="black" stroke-linecap="round"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <!-- <div class="custom-cursor">
      <div id="cursor-big" class="custom-cursor__ball custom-cursor__ball--big"></div>
    </div> -->
  </section>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/NewFooter.vue";
import anime from "animejs";
// import { TweenMax } from 'gsap/TweenMax';
import {mapMutations} from "vuex";

export default {
  name: "cases",
  components: {
    Header,
    Footer,
  },
  methods: {
    ...mapMutations(["setCurrentPage"]),

    toLink(link) {
      this.$router.push(link);
    },

    handleLink(e, list) {
      e.preventDefault();
      e.target.classList.add("activeItem");
      list.classList.add("nonActive");
      const hrefAtr = e.target.getAttribute("href");
      setTimeout(() => {
        this.$router.push(hrefAtr);
      }, 1000);
    },
  },
  mounted() {
    this.setCurrentPage("cases");
    window.scrollTo(0, 0);
    const list = document.querySelector(".cases__list");
    list.addEventListener("click", (e) => {
      this.handleLink(e, list);
    });

    setTimeout(() => {
      const cases = document.querySelector("#cases");
      const textWrapperAll = cases.querySelectorAll(".text-wrapper .letters");
      textWrapperAll.forEach((e, index) => {
        const textWrapper = e;

        if (!Number.isInteger(index / 2)) {
          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter strong'>$&</span>");
        } else {
          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
        }

        anime
          .timeline({
            loop: false,
          })
          .add({
            targets: ".text-wrapper .letter",
            translateY: ["1.2em", 0],
            translateZ: 0,
            duration: 1000,
            delay: (el, i) => i * 50,
          });
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/animation.scss";

.section {
  &__title {
    font-size: 60px;
    line-height: 64px;
    font-weight: 300;
    text-align: left;
    color: var(--text-secondary-color);
    position: relative;
    margin-bottom: 0;

    .text-wrapper {
      position: relative;
      display: inline-block;
      overflow: hidden;
      padding: 0.1em 0.15em 0.1em 0;

      &:nth-of-type(2) {
        padding-top: 0;
      }
    }

    ::v-deep .letter {
      font-size: 60px;
      line-height: 64px;
      display: inline-block;

      &.strong {
        font-family: "TimesNewRoman-I";
        font-size: 80px;
        font-style: italic;
        color: var(--footer-text-color);
      }
    }
  }
}

.cases {
  &__content {
    max-width: calc(1140px + 90px);
    width: 100%;
    margin: 0 auto;
    padding: 171px 45px 151px 45px;
  }

  &__top {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    // grid-column-gap: 84px;
  }

  &__description {
    color: #92a1ae;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.8;
    text-align: left;
    max-width: 84%;
    display: flex;
    align-items: center;
  }

  &__list {
    margin-top: 72px;

    @media screen and (min-width: 1023px) {
      &:hover {
        .cases__item {
          opacity: 0.5;
        }
      }
    }

    &:active {
      .cases__item {
        opacity: 0.5;
      }
    }
  }

  &__img {
    display: none;
    position: absolute;
    left: -2%;
    // top: 0%;
    opacity: 0;
    max-width: 300px;
    object-fit: contain;
    z-index: 2;
    transition: all ease 0.4s;
  }

  &__item {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    // grid-column-gap: 84px;
    text-align: left;
    padding: 30px 0 30px 15px;
    align-items: center;
    position: relative;
    margin-bottom: -1px;

    transition: all 0.2s ease;

    &::after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: "";
      width: 100%;
      height: 1px;
      background-color: #92a1ae;
    }

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      width: 100%;
      height: 1px;
      background-color: #92a1ae;
    }

    &:nth-of-type(1) {
      .cases__item--text,
      .cases__item--title,
      .cases__item--icon,
      .cases__item::after {
        animation-delay: 0s;
      }
    }

    &:nth-of-type(2) {
      .cases__item--text,
      .cases__item--title,
      .cases__item--icon,
      .cases__item::after {
        animation-delay: 0.4s;
      }
    }

    &:nth-of-type(3) {
      .cases__item--text,
      .cases__item--title,
      .cases__item--icon,
      .cases__item::after {
        animation-delay: 0.8s;
      }
    }

    &:nth-of-type(4) {
      .cases__item--text,
      .cases__item--title,
      .cases__item--icon,
      .cases__item::after {
        animation-delay: 1s;
      }
    }

    &:nth-of-type(5) {
      .cases__item--text,
      .cases__item--title,
      .cases__item--icon,
      .cases__item::after {
        animation-delay: 1.2s;
      }
    }

    &:nth-of-type(6) {
      .cases__item--text,
      .cases__item--title,
      .cases__item--icon,
      .cases__item::after {
        animation-delay: 1.4s;
      }
    }

    &:nth-of-type(7) {
      .cases__item--text,
      .cases__item--title,
      .cases__item--icon,
      .cases__item::after {
        animation-delay: 1.6s;
      }
    }

    &:nth-of-type(8) {
      .cases__item--text,
      .cases__item--title,
      .cases__item--icon,
      .cases__item::after {
        animation-delay: 1.8s;
      }
    }

    &:nth-of-type(9) {
      .cases__item--text,
      .cases__item--title,
      .cases__item--icon,
      .cases__item::after {
        animation-delay: 2s;
      }
    }

    &:nth-of-type(10) {
      .cases__item--text,
      .cases__item--title,
      .cases__item--icon,
      .cases__item::after {
        animation-delay: 2.2s;
      }
    }

    &:nth-of-type(11) {
      .cases__item--text,
      .cases__item--title,
      .cases__item--icon,
      .cases__item::after {
        animation-delay: 2.4s;
      }
    }

    &:nth-of-type(12) {
      border-bottom: 1px solid #92a1ae;

      .cases__item--text,
      .cases__item--title,
      .cases__item--icon,
      .cases__item::after {
        animation-delay: 2.6s;
      }
    }

    &--title {
      color: var(--footer-text-color);
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      position: relative;
      // width: fit-content;
      max-width: 100%;
      margin-right: 15px;

      .underline {
        width: calc(100%);
        background-image: linear-gradient(transparent calc(100% - 1px), var(--footer-text-color) 1px);
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }

    &--details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }

    &--text {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--text-secondary-color);
    }

    &--icon {
      transition: all 0.1s ease-in;
      width: 40px;
      max-width: 40px;
      min-width: 40px;

      path {
        stroke: var(--footer-text-color);
      }
    }

    &:hover,
    &:active {
      opacity: 1 !important;

      //.cases__img {
      //  opacity: 1;
      //}

      .cases__item--icon {
        transform: rotate(45deg);
      }
    }
  }
}

.appear {
  .cases {
    &__item {
      &--title,
      &--text,
      &--icon {
        animation: growsUp 0.8s alternate backwards;
      }
    }
  }

  &.cases__item::after {
    animation: widthProgress 0.8s alternate forwards;
  }

  &.cases__item:nth-of-type(1)::before {
    animation: widthProgress 0.8s alternate forwards;
  }
}

@media screen and (max-width: 1600px) {
  .cases {
    &__description {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .cases {
    &__content {
      padding-top: 140px;
    }
  }
}

@media screen and (max-width: 960px) {
  .cases {
    &__description {
      font-size: 14px;
    }

    &__list {
      margin-top: 39px;
    }

    &__item {
      grid-column-gap: 22px;
    }

    &__top {
      grid-column-gap: 22px;
    }
  }
  .section {
    &__title {
      font-weight: 300;
      font-size: 48px;
      line-height: 52px;

      .text-wrapper {
        padding: 0;
      }

      ::v-deep .letter {
        font-size: 48px;
        line-height: 52px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .section {
    &__title {
      font-weight: 300;
      font-size: 40px;
      line-height: 52px;

      .text-wrapper {
        padding: 0;
      }

      ::v-deep .letter {
        font-size: 40px;
        line-height: 52px;

        &.strong {
          font-size: 66px;
          // line-height: 60px;
        }
      }
    }
  }
  .cases {
    &__content {
      padding: 100px 45px 60px 45px;
    }

    &__list {
      &.nonActive {
        & > .cases__item {
          opacity: 0.5;
        }

        & > .cases__item.activeItem {
          opacity: 1;
        }
      }
    }

    &__item {
      padding: 18px 0;

      &--title {
        font-size: 20px;
        line-height: 28px;
      }

      &--text {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &__img {
      display: none;
    }
  }
}

@media screen and (max-width: 660px) {
  .cases {
    &__content {
      padding-top: 76px;
    }

    &__title {
      max-width: 300px;
      margin-bottom: 8px;
    }

    &__top {
      grid-template-columns: 1fr;
    }
  }
  .section {
    &__title {
      font-weight: 300;
      font-size: 28px;
      line-height: 40px;

      .text-wrapper {
        padding: 0;
      }

      ::v-deep .letter {
        font-size: 28px;
        line-height: 40px;

        &.strong {
          font-size: 44px;
          // line-height: 60px;
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .cases {
    &__content {
      padding-left: 20px;
      padding-right: 20px;
    }

    &__list {
      margin-top: 32px;
    }

    &__item {
      grid-template-columns: 1fr;

      &--title {
        max-width: 80%;
      }

      &--text {
        max-width: 80%;
        margin-top: 16px;
      }

      &--details {
        position: unset;
      }

      &--icon {
        position: absolute;
        right: 0;
        top: calc(50% - 20px);
      }

      &:hover,
      &:active {
        opacity: 1 !important;

        //.cases__img {
        //  opacity: 1;
        //}

        .cases__item--icon {
          transform: rotate(45deg);
        }
      }
    }
  }
}
</style>
